import { useAppTheme } from 'hooks/UseAppTheme';
import { ResolveFrontend } from 'phoenix/constants';
import React from 'react';
import { TickerTape } from 'react-ts-tradingview-widgets';
import './TradingViewMarquee.css';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';

// The title and proname properties are used to display the name of the symbol in the ticker tape.
// The symbol property is unused currently, but could be used to redirect the link from the ticker tape's link to the appropriate advanced chart screen.
// Likely hacky, but until we either make our own ticker tape or use a different library, this is the best we can do.
export const TradingViewTickerSymbols = [
    {
        title: 'NASDAQ 100',
        proName: 'SKILLING:NASDAQ',
        symbol: 'QQQ'
    },
    {
        proName: 'CME_MINI:ES1!',
        title: 'E-Mini S&P',
        symbol: 'F:ES'
    },
    {
        title: 'DOW 30',
        proName: 'US30',
        symbol: 'DJX',
        showOptions: true
    },
    {
        title: 'S&P 500',
        proName: 'SPXUSD',
        symbol: 'SPX',
        showOptions: true
    },
    {
        title: 'VIX',
        proName: 'PEPPERSTONE:VIX',
        symbol: 'VIX',
        showOptions: true
    }
];

/* also known as the TickerBar */
export const TradingViewMarquee = React.memo(() => {
    const [colorTheme] = useAppTheme();

    return (
        <>
            <div id={'tour-06-tradingViewMarquee'} style={{ width: '100%', position: 'sticky', bottom: 0, zIndex: 100 }}>
                <TickerTape
                    displayMode='regular'
                    colorTheme={colorTheme}
                    symbols={TradingViewTickerSymbols}
                    showSymbolLogo={false}
                    largeChartUrl={ResolveFrontend() + '/security/symbol/'}
                    copyrightStyles={{
                        parent: {
                            fontSize: '0px !important',
                            color: 'transparent'
                        }
                    }}
                />
            </div>
        </>
    );
});
